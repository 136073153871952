import Vue from 'vue';

export default {
    getEmpleados(parametros){
        return Vue.axios.get("empleados/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getEmpleado(id){
        return Vue.axios.get("empleados/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateEmpleado(parametros){
        return Vue.axios.post("empleados/update-empleado", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteEmpleado(id){
        return Vue.axios.delete("empleados/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    eliminarEmpleado(parametros){
        return Vue.axios.post("empleados/eliminar", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    reingresoEmpleado(parametros){
        return Vue.axios.post("empleados/reingreso", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addEmpleado(parametros){
        return Vue.axios.post("empleados", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    importarEmpleados(parametros){
        return Vue.axios.post("empleados/importar", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getDashboard(parametros){
        return Vue.axios.get("empleados/dashboard", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    asignacionEmpleado(parametros){
        return Vue.axios.post("empleados/asignacion", parametros ).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    cumpleaniosEmpleado(parametros){
        return Vue.axios.get("empleados/cumpleanios", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getKardexCollections(parametros){
        return Vue.axios.post("empleados/load-kardex-collections", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getHistorico(parametros){
        return Vue.axios.get("historico_empleado/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getDashboardNominas(parametros){
        return Vue.axios.post("dashboard/nominas", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addBeneficiario(parametros){
        return Vue.axios.post("beneficiarios", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateBeneficiario(parametros){
        return Vue.axios.put("beneficiarios", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteBeneficiario(id){
        return Vue.axios.delete("beneficiarios/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    historicoVacaciones(parametros){
        return Vue.axios.post("reportes/historico-vacaciones", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}