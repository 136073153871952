//IMPORTS

//APIS
import auth from '../../api/auth';
import apiPermissions from '../../api/permisos';

//PLUGINS
import Vue from 'vue';
import axios from 'axios';


//FUNCIONES VUEX.ACTIONS(context) el parametro "context" hace referencia a las mutaciones.

/*
Entrada: user Objeto user{}
Salida: 
Descripción: Se ejecuta el API login() para que la infomacion 
             obtenida llene el STATE objeto user mediante 
             la MUTATION setUser() para inciar sesion y colocar 
             el token en la varaible de sesion para las peticiones axios.
*/
export async function singIn(context, user ) {
    await auth
        .login(user)
        .then(async (response)=>{
            var data = [];
            data = {
                user,
                "token"         : response.access_token,
                "expires_at"    : response.expires_at,
                "datos"         : response.user,
                "menu"          : response.menu,
                "foto_usr"      : response.user.profile_picture,
                "logotipo"      : response.user.foto_empresa
            };

            await userPermissions(response.user)
            
            if(localStorage.getItem("versionCliker")){
                let version =localStorage.getItem("versionCliker")
                if(version === response.version){
                    //Esta actualizado Cliker
                }
                else {
                    //No esta actualizado Cliker
                    localStorage.setItem("versionCliker", response.version) 
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 2000);
                }
            }
            else{
                //No hay version en localStorage, se actualiza Cliker para evitar que se tenga versiones antiguas
                localStorage.setItem("versionCliker", response.version)

                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            }

            

            context.commit('setUser',data);
            localStorage.setItem("tokenPB", response.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer '+ response.access_token;
        }).catch(err=>{
            /* let message = err.response.data.error

            if(message == "Unauthorized"){
                message = "Los datos que ingresaste no son correctos, intenta nuevamente."
            }else if(message == "Acceso no autorizado al sistema de nominas."){
                message = "Cuenta no registrada."
            }else{
                if(message.email !== undefined){
                    message = message.email[0];
                }else if(message.password !== undefined){
                    message = message.password[0];
                }
            } */

            var message = null;
            message = "Favor de validar los datos."
            context.commit('authError',message);

            //context.commit('authError', message);
        })
}

async function userPermissions(user){
    setTimeout(() => {
        let parametros = {
            usuario_id : user.id
        }
        apiPermissions.getAllPermissionsUser(parametros).then(async response => {
            let arr = response.data
            let arr2 = []
            
            await arr.map(async val => {
                await val.permisos.map(val2 => {
                    if(val2.checked == true){
                        arr2.push(val2.name)
                    }
                })
            })

            sessionStorage.setItem("permisos", JSON.stringify(arr2))
        })
        .catch(err => {
            console.log(err)
        })
    }, 500);
}

/*
Entrada: user Objeto user{}
Salida: 
Descripción: Se envia STATE objeto user mediante la MUTATION 
             update_password() para actualizar la contraeña 
             nueva depues de un cambio de contraseña dentro del sistema.
*/
export async function update_password(context, user ) {
    context.commit('update_password',user);
}

export async function update_cliente(context, data) {
    context.commit('update_cliente',data);
}

export async function updateUserData(context, user) {
    try{
        let url = 'refresh';
        await Vue.axios({
            method: "POST",
            url: url,
            data: user
        })
        .then((response) => {
            var data = [];
            data = {
                "datos"         : response.data.user,
                "menu"          : response.data.menu,
                "foto_usr"      : response.data.user.profile_picture
            };

            context.commit('updateUserData',data);
        })
    }
    catch(e){
        var message = null;
        console.log(e);
        if(e.response.data.error == "Unauthorized"){
            message = "Favor de validar los datos.";
        }else{
            message = e.response.data.error;
        }
        context.commit('authError',message);
    }
}

export async function updateUserFoto(context, foto) {
    context.commit('updateUserFoto',foto);
}

export async function vuexUpdateLogo(context, logo) {
    if(logo == null){
        context.commit('vuexUpdateLogo',null);
    }
    else {
        context.commit('vuexUpdateLogo',logo);
    }
}


/*
Entrada: user Objeto user{}
Salida: 
Descripción: Se ejecuta el API logout() el state del usuario para terminar 
             la sesion y se actualiza el STATE user{} ajacutando el MUTATION logout().
*/
export async function logout(context, user ) {
    await auth
    .logout(user)
    .then((response)=>{
        context.commit('logout');
    }).catch(err=>{

    })
}
