import Vue from 'vue';

export default {

    //CRUD Tipos de permisos
    async createPermissions(parametros){
        return await Vue.axios.post("permisos", parametros ).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    async updatePermissions(parametros){
        return await Vue.axios.put("permisos", parametros ).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    async deletePermission(id){
        ///// cambiar no sirve
        return await Vue.axios.delete("permisos/"+ id).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    async deletePermissions(parametros){
        return await Vue.axios.post("permisos/deletePermissions", parametros ).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    async getPermissions(){
        return await Vue.axios.get("permisos").then((response)=>Promise.resolve(response)).catch((error)=> Promise.reject(error));
    },
    async findPermissions(parametros){
        return await Vue.axios.get("permisos/findPermissions", {params: parametros}).then((response)=>Promise.resolve(response)).catch((error)=>Promise.reject(error));
    },


    // API's permisos usuarios
    async getAllPermissionsUser(parametros){
        return await Vue.axios.get("permisos/getAllPermissionsUser",{ params: parametros }).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    async assignRolesToUsers(parametros) {
        return await Vue.axios.post("permisos/assignRolesToUsers", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error))
    },
    async updatePermissionsUser(parametros){
        return await Vue.axios.put("permisos/updatePermissionsUser", parametros ).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    async syncPermissionsUser(parametros) {
        return await Vue.axios.post("permisos/syncPermissionsUser", parametros).then((response)=>Promise.resolve(response)).catch((error)=> Promise.reject(error))
    },


    // API's permisos roles
    async getPermissionsNotInRole(parametros){
        return await Vue.axios.get("permisos/getPermissionsNotInRole", {params: parametros}).then((response) => Promise.resolve(response)).catch((error)=> Promise.reject(error));
    },
    async getPermissionsRol(parametros){
        return await Vue.axios.get("permisos/getPermissionsRol",{ params: parametros }).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    async syncPermissionsRol(parametros) {
        return await Vue.axios.post("permisos/syncPermissionsRol", parametros).then((response) => Promise.resolve(response)).catch((error)=> Promise.reject(error))
    },


    //CRUD Categorias
    async createCategoria(parametros){
        return await Vue.axios.post("permisos/categorias", parametros ).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    async updateCategoria(parametros){
        return await Vue.axios.put("permisos/categorias", parametros ).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    async deleteCategoria(id){
        return await Vue.axios.delete("permisos/categorias/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async getCategoria(id){
        return await Vue.axios.get("permisos/categorias/"+id).then((response)=>Promise.resolve(response)).catch((error)=> Promise.reject(error));
    },
    async findCategorias(parametros){
        return await Vue.axios.get("permisos/categorias/find", {params: parametros}).then((response)=>Promise.resolve(response)).catch((error)=>Promise.reject(error));
    },

}
