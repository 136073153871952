<template>
  <div id="mySidebar" class="sidebar" @mouseover="isMiniHover?toggleSidebarHover(false):null"
    @mouseleave="isMiniHover?toggleSidebarHover(true):null">
    <v-overlay :value="showOverlay"  :z-index="999" >
        <div class="align-center justify-center text-center">
            <v-progress-circular
                indeterminate
                :size="100"
                :width="7"
                color="#2DB9D1"
            >
                <v-img
                    src="/static/icon/favicon.ico"
                    :width="60"
                ></v-img>
            </v-progress-circular>
            <p class="mt-5">{{"Cargando permisos"}}</p>
        </div>
    </v-overlay>
    <div  class=" logo-container">
      <template color="#0b1148" size="62">
        <!-- <img :src="logo" :class="!isMini ? 'logo' : 'logo-hide'"/> -->
        <img :src="logotipoEmpresa" :class="!isMini ? 'logo' : 'logo-hide'"/>
      </template>
        <img v-if="!isMiniHover" @click="toggleSidebar(true)" class="icon-hide" src="/static/icon/logo-arrow-back.svg" />
        <img v-else @click="toggleSidebar(false)" :class="['icon-show',isMini?'mini':'']" src="/static/icon/logo-arrow-forward.svg" />
    </div>

    <div id="menuItems" style="height: 100%; overflow-x: scroll; padding-bottom: 50px">
      <template v-for="(item, index) in menus">
        <template v-if="item.sistema == 'todos' || item.sistema == 'nominas'">
          <div v-if="item.hijos" :key="index">
            <!-- euicab segmento original
            <a :class="selected == item.id ? 'item-menu selected' : 'item-menu'" href="javascript:void(0)" @click="selected = item.id">
              <i class="material-icons">{{item.icono}}</i>
              <span v-if="!isMini" class="icon-text">{{item.nombre}}</span>
              <img v-if="!isMini && selected != item.id" class="icon-forward" src="/static/icon/arrow-forward.png" />
              <img v-if="!isMini && selected == item.id" class="icon-forward" src="/static/icon/arrow-down.png" />
            </a>
            -->
            <a :class="selected == item.id ? 'item-menu selected' : 'item-menu'" href="javascript:void(0)" @click="openSubMenu(item.id)">
              <div class="d-flex justify-content-between">
              <!--<i class="material-icons">{{item.icono}}</i>-->
              <i :color="selected == item.id ? '#1E2245':'#FFFFFF'" class="mr-4 material-icons-outlined">{{item.icono}}</i>
              <span v-if="!isMini" class="icon-text">{{item.nombre}}</span>
              <img v-if="!isMini && selected != item.id" class="icon-forward " src="/static/icon/arrow-forward.svg" />
              <img v-if="!isMini && selected == item.id" class="icon-forward icon-size10" src="/static/icon/arrow-down.svg" />
              </div>
            </a>

            <transition name="slide">
              <div v-if="selected == item.id && !isMini"  class="menu__subcontainer">
                <ul class="menu__subnav">
                  <li v-for="(itemSubmenu, indexS) in item.hijos" :key="'s' + indexS" class="menu__item ">
                    <router-link :to="itemSubmenu.url" v-slot="{href, isActive}">
                      <router-link :to="href" :class="isActive ? 'menu__link selected2' : 'menu__link'">
                        <i :class="[isActive ? 'menu__link-bullet selected3' : 'menu__link-bullet','material-icons']">lens</i>
                        <span>{{itemSubmenu.nombre}}</span>
                      </router-link>
                    </router-link>
                  </li>
                </ul>
              </div>
              <div v-if="selected == item.id && isMini" class="menu__subcontainer mini__subcontainer">
                <span>
                  <i class="menu__link-bullet material-icons">lens</i>
                  <i class="menu__link-bullet material-icons">lens</i>
                  <i class="menu__link-bullet material-icons">lens</i>
                </span>
              </div>
            </transition>
          </div>

          <div v-else :key="index" @click="selected = 0">
            <router-link :to="item.url" v-slot="{href, isActive}">
              <router-link :class="isActive ? 'selected item-menu' : 'item-menu'" :to="href">
                <div class="d-flex justify-content-between">
                <i :color="isActive ? '#1E2245':'#FFFFFF'" class="mr-4 material-icons-outlined">{{item.icono}}</i>
                <span v-if="!isMini" class="icon-text">{{item.nombre}}</span>
                </div>
                <!-- <img v-if="!isMini" class="icon-forward" src="/static/icon/arrow-forward.png" /> -->
              </router-link>
            </router-link>
          </div>
        </template>
      </template>

      
      <div style="padding-left: 62.5px; padding-bottom: 70px; padding-top: 25px;">
        <img src="/static/bg/cliker_w.png" :class="!isMini ? 'logo-footer' : 'logo-footer-hide'" />
      </div>
      
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import Notify from "@/plugins/notify";
import { mapActions, mapState } from 'vuex';
import empleadoApi from '@/api/empleados';
import configuracionesApi from "@/api/configuraciones";
import urlImg from '../plugins/Urlimagen';
import apiPermissions from '@/api/permisos';

export default {
  name: 'menu-drawer',
  data() {
      return {
        logo          : "/static/bg/cliker_w.png",
        isSideBarOpen : true,
        isMini        : false,
        isMiniHover   : false,
        menus         : [],
        rol           : null,
        selected      : undefined,
        showOverlay: false,
      }
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false
    },
    right: {
      type: Boolean,
      required: false
    },
    width: {
      type: [String],
      required: false,
      default: '300'
    },
    disableEsc: {
      type: Boolean,
      required: false
    },
    noOverlay: {
      type: Boolean,
      required: false
    },
    onStateChange: {
      type: Function,
      required: false
    },
    burgerIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    crossIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    disableOutsideClick: {
      type: Boolean,
      required: false,
      default: false
    },
    boolLogo: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    ...mapState('auth',["datos", "logotipo"]),
    logotipoEmpresa(){
      if(this.logotipo == null){
        return "/static/bg/cliker_w.png"
      }
      else {
        return urlImg.getURL() + this.logotipo
      }
    }
  },
  methods: {
    ...mapActions('auth',['logout']),
    _logout() {
      this.logout();
      //this.$router.push('/login');
      this.$router.push('/');
    },
    openSubMenu(id){
      if(this.selected == id){
        this.selected = 0;
      }
      else{
        this.selected = id;
      }
    },
    openMenu() {
      this.$emit('openMenu');
      this.isSideBarOpen = true;
    
      this.$nextTick(function() {
        //this.$refs.sideNav.style.width = this.width ? this.width + 'px' : '300px';

        //document.getElementById("inspire").style.marginLeft = "110px";
        //document.getElementsByClassName("v-toolbar")[1].style.paddingLeft = "50px";
      });
    },
    closeSubMenu(){
      alert();
    },
    closeMenu() {
      this.$emit('closeMenu');
      this.isSideBarOpen = false;
      document.body.classList.remove('bm-overlay');
      //this.$refs.sideNav.style.width = '0px';

      //document.getElementById("inspire").style.marginLeft = "0px";
      // sdocument.getElementsByClassName("v-toolbar")[1].style.paddingLeft = "0px";
    },
    closeMenuOnEsc(e) {
      e = e || window.event;
      if (e.key === 'Escape' || e.keyCode === 27) {
        this.closeMenu();
      }
    },
    documentClick(e) {
      let element   = this.$refs.bmBurgerButton;
      let target = null;
      if (e && e.target) {
        target = e.target;
      }
      if (element && element !== target && !element.contains(target) && e.target.className !== 'bm-menu' 
          && this.isSideBarOpen && !this.disableOutsideClick
      ) {
        this.closeMenu();
      }
    },
    init() {
      if(this.$session.get('usuario') == undefined){
        localStorage.removeItem("token_checador");
        localStorage.removeItem("tokenPB");
        localStorage.removeItem("vuex");
        /* this.$router.push('Login'); */
        this.$router.push('/');
      }
      else{
        let session = this.$session.get('usuario');
        let rol     = session.rol.name;
        this.menus  = this.$session.get('menu');

        if(session.foto_empresa != null) {
          if(rol == "admin-empresa" && session.configuracion) {
            this.getLogoMenu();
          } else {
            this.logo = urlImg.getURL() + session.foto_empresa;
          }
        } else if((rol == "admin" || rol == "admin-empresa") && session.configuracion && this.boolLogo === false) {
          this.getLogoMenu();
        }

        switch(rol) {
          case 'empleado': this.session_empleado(); break;
        }

        const elementScroll = document.querySelector('#menuItems');
        if(elementScroll) {
            /* const ps = new PerfectScrollbar(elementScroll, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20
            }); */
            var ps = new PerfectScrollbar(elementScroll);

            var scrollbarY = elementScroll.querySelector('.ps__rail-y');

            elementScroll.onscroll = function () {
                scrollbarY.style.cssText = `
                                                top: ${this.scrollTop}px!important; 
                                                height: 300px; 
                                                background-color:transparent !important; 
                                                right: ${-this.scrollLeft}px
                                            `;
            }
        }
      }
    },
    async session_empleado(){
      let usuario = this.$session.get('usuario');
      let param = {
        rol_name:usuario.rol.name, 
        usuario_id:usuario.id, 
        activo: true, 
        empleado_id: usuario.empleado_id,
        //correo: usuario.email
      }
      await empleadoApi.getEmpleados(param).then((response) => {
        let data = response.data[0];
        this.$session.set('empleado',data);
      })
      .catch(err => {
        console.log(err); 
      });
    },
    getLogoMenu() {
      let session = this.$session.get('usuario');

      if(session.configuracion) {
        let params = {
          cliente_id  : session.cliente_id,
          empresa_id  : session.empresa_id,
        }

        configuracionesApi.initialUpdate(params)
          .then((response) => {
            if(response.logo != null) {
              this.logo = urlImg.getURL() + response.logo;
            }
          })
          .catch((error) => {
            console.log("Error", error);
            Notify.ErrorToast(error);
          });
      }
    },
    toggleSidebar(value) {
      if(!value) {
        document.getElementById("mySidebar").style.width                  = "235px";
        document.getElementById("v-content").style.marginLeft             = "235px";
        $(".item-menu").css("paddingLeft", "45px");
        $(".icon-text").css("visibility", "visible");
        $(".icon-forward").css("visibility", "visible");
        this.isMini = false;
        this.isMiniHover = false;
      } else {
        document.getElementById("mySidebar").style.width            = "80px";
        document.getElementById("v-content").style.marginLeft       = "80px";
        $(".item-menu").css("paddingLeft", "28px");
        $(".item-menu").css("transition", "0.5s");
        $(".icon-text").css("visibility", "hidden");
        $(".icon-forward").css("visibility", "hidden");
        this.isMini = true;
        this.isMiniHover = true;
      }
    },
    toggleSidebarHover(value) {
      if(!value) {
        document.getElementById("mySidebar").style.width                  = "235px";
        document.getElementById("v-content").style.marginLeft             = "235px";
        $(".item-menu").css("paddingLeft", "45px");
        $(".icon-text").css("visibility", "visible");
        $(".icon-forward").css("visibility", "visible");
        this.isMini = false;
      } else {
        document.getElementById("mySidebar").style.width            = "80px";
        document.getElementById("v-content").style.marginLeft       = "80px";
        $(".item-menu").css("paddingLeft", "28px");
        $(".item-menu").css("transition", "0.5s");
        $(".icon-text").css("visibility", "hidden");
        $(".icon-forward").css("visibility", "hidden");
        this.isMini = true;
      }
    },
    userPermissions(){
        this.showOverlay = true;
        let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))

        if(permisosStorage != null){
          this.showOverlay = false;
          return
        }
        setTimeout(() => {
            let datosLogin = this.$session.get("usuario");
            let parametros = {
                usuario_id : datosLogin.id
            }
            apiPermissions.getAllPermissionsUser(parametros).then(async response => {
                let arr = response.data
                let arr2 = []
                
                await arr.map(async val => {
                    await val.permisos.map(val2 => {
                        if(val2.checked == true){
                            arr2.push(val2.name)
                        }
                    })
                })

                sessionStorage.setItem("permisos", JSON.stringify(arr2))
                setTimeout(() => {
                  this.showOverlay = false;
                }, 100);
                
            })
            .catch(err => {
                console.log(err)
            })
        }, 500);
    }
  },
  mounted() {
    if (!this.disableEsc) {
      document.addEventListener('keyup', this.closeMenuOnEsc);
    }

    this.userPermissions()

    document.getElementById("v-content").style.marginLeft       = "235px";

    this.init();
  },
  created: function() {
    document.addEventListener('click', this.documentClick);
  },
  destroyed: function() {
    document.removeEventListener('keyup', this.closeMenuOnEsc);
    document.removeEventListener('click', this.documentClick);
  },
  watch: {
    isOpen: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        this.$nextTick(() => {
          if (!oldValue && newValue) {
            this.openMenu();
          }
          if (oldValue && !newValue) {
            this.closeMenu();
          }
        });
      }
    },
  }
}
</script>

<style scoped>
  .sidebar {
    height: 100%;
    width: 245px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #1E2245;
    transition: 0.5s;
    /*padding-top: 20px;*/
    white-space: nowrap;
    max-width: 100%;
  }
  .sidebar a {
      padding-left: 45px;
      padding-top: 20px;
      padding-bottom: 20px;
      text-decoration: none;
      display: block;

      font-family: 'Montserrat';
      font-size: 12px !important;
      font-weight: 500;
      color: #FFFFFF;
  }
  .sidebar a:hover {
      font-size: 17px;
      font-weight: 700;
  }
  .selected {
    font-size: 17px !important;
    font-weight: 700 !important;
  }
  .selected2 {
    font-size: 17px !important;
    font-weight: 700 !important;
    background: #1F255B !important;
  }
  .item-menu{
    transition: 0.3s;
  }
  .item-menu.selected { 
    background-color:#efefef;
    color:#1E2245;/*#1F2454;
    background-color:#efefef;
    color:#1E2245;/*#1F2454;
    padding-top:12px;
    padding-bottom:12px;
    margin: 10px;*/
  }
  .menu__item.selected{
     background-color:#efefef;
    color:#1E2245;/*#1F2454;
    background-color:#efefef;
    color:#1E2245;/*#1F2454;
    padding-top:12px;
    padding-bottom:12px;
    margin: 10px;*/
  }
  .material-icons {
      margin-right: 15px;
      vertical-align: middle;
  }
  .icon-text{
    width:100%;
    display: flex;
    align-items: center;
  }
  .icon-hide {
      position: relative;
      padding-right: 35px;
      margin-left: 15px;
      cursor: pointer;
      float: right;
      transition: 0.5s;
  }
  .icon-show {
      position: relative;
      padding-right: 35px;
      cursor: pointer;
      float: right;
      margin-left: 15px;
      transition: 0.5s;
  }
  .mini{
    padding-left:32px ;
  }
  .icon-forward {
      position: relative;
      padding-right: 35px;
  }
  .icon-size10 {
    margin-top:6px;
    height: 10px;
  }

  .menu__subcontainer {
    background-color: #151939;
  }

  .menu__subnav {
    list-style: none !important;
    padding-left: 0px !important;
  }


  .menu__subnav .menu__item {
    margin: 0;
    border-bottom: 1px solid #1E2245;
    
    
  }

  .menu__subnav > .menu__item > .menu__link {
    display: flex;
    align-items: center;
    min-height: 40px;
    color: #E0E0E0;
    font-size: 12px !important;
    transition: 0.3s;
  }

  .menu__subnav > .menu__item > .menu__link > .menu__link-bullet {

    color: #2DB9D1;
    font-size:5px;
    /* padding-top: 3px;*/
  transition: 0.3s;
  }

  .menu__subnav > .menu__item > .menu__link > .menu__link-bullet.selected3 {

    color: #2DB9D1 !important;
    font-size:8px;
    /* padding-top: 3px;*/
  }

  a.menu__link:hover {
      font-size: 16px;
      font-weight: 700;
      background: #1F255B;
  }

  .slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
  /**euicab nuevos estilos */
  .middle > * {
    vertical-align: middle !important;
  }
  .logo-container{
    display: flex;
    align-items: center;
    background: #1E2245;
    height: 74px;
  }
  .logo{
    max-height: 74px;;
    margin-left:62.5px;
    max-width: 110px;
    text-align: center;
    transition: 0.5s;
  }
  .logo-hide{
    width: 110px;
    height: 34px;
    text-align: center;
    margin-left: -110px;
    transition: 0.5s;
  }
  .logo-footer {
    width: 110px;
    max-height: 80px;
    text-align: center;
    transition: 0.5s;
  }

  .logo-footer-hide {
    width: 110px;
    height: 34px;
    text-align: center;
    margin-left: -180px;
    transition: 0.5s;
  }
  .menu__subcontainer.mini__subcontainer {
    padding: 20px 26px 21px 20px;
  }
  .menu__subcontainer.mini__subcontainer > span > i {
    color: #2DB9D1;
    font-size: 9px;
    margin-right: 5px !important;
  }
  /**Fin nuevos estilos */
  #menuItems{
    position: relative;
  }

</style>